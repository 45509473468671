// import { ContentTitle, MenuId, MenuName, menuList } from "modules/sidebar";
import { atom } from "recoil";



export interface FooterInfo {
  workbayName: string;
  centerName: string;
  managerName: string;
}
export const initialFooterInfo: FooterInfo = {
  workbayName: "",
  centerName: "",
  managerName: "",
};

export const footerInfoAtom = atom<FooterInfo>({
  key: "footerInfoAtom",
  default: {
    workbayName: "",
    centerName: "",
    managerName: "",
  },
});
