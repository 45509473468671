import { authEnum } from "config/auth";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { authTokenAtom } from "recoil/authAtom";

export function useLogout() {
  const navigate = useNavigate();
  const setAuthTokenAtom = useSetRecoilState(authTokenAtom);
  

  return () => {
    setAuthTokenAtom("");
    localStorage.clear();
    localStorage?.removeItem(authEnum.authToken);
    localStorage?.removeItem('siteId');

    navigate("/login");
  };
}
