import styled from "@emotion/styled";
import ProtectedRoute from "components/Router/ProtedctedRoute";
import Footer from "components/layout/footer";
import { Dashboard } from "pages/dashboard";
import { Dashboards } from "pages/dashboards";
import Auth from "pages/login";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useCheckAuth } from "utils/checkAuth";

function Index() {
  useLocation();
  const auth = useCheckAuth();

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route element={<Layout auth={auth} />}>
        <Route
          path="/login"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath="/dashboards">
              <Auth />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute redirectCondition={!auth} redirectPath="/login">
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboards"
          element={
            <ProtectedRoute redirectCondition={!auth} redirectPath="/login">
              <Dashboards />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}

const Layout = ({ auth } : { auth : boolean }) => {
  return (
    <Main>
      {!auth ? (
        <Outlet />
      ) : (
        <>
          <Content>
          <Outlet />
          </Content>
          <Footer />
        </>
        )}
    </Main>
  );
};
const Main = styled.main`
  min-width: 100vw;
  min-height: 100vh;
  width: 100vw;
  /* height: 100vh; */
  background: ${({ theme }) => theme.backgroundColor};
  background-color: #4d4d4d;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
`;

export default Index;
