import styled from "@emotion/styled";

interface HeaderBoxProps {
    children: React.ReactNode;
}

export const HeaderBox = ({ children } : HeaderBoxProps) => {
    return (
        <Container>{children}</Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding : 32px;
    display : flex;
    justify-content: space-between;
`
