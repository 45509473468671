import { Configuration, DefaultApi } from '@sizlcorp/mbk-api-document/dist/models';
import axios from "axios";
export const BASE_URL = process.env.REACT_APP_BASE_URL ?? "https://mbk.sizl.co.kr/api"
export const axiosInstance: any = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
    },
    timeout: 1000 * 60 * 60 * 24 * 7,
});

axiosInstance.interceptors.request.use((config: any) => {
    if (!config.headers) return config;
    const accessToken = localStorage.getItem('authToken');

    if (accessToken && config.headers) {
        config.headers["Authorization"] = 'Bearer ' + accessToken
    }
    return config;
})

axiosInstance.interceptors.response.use((res: any) => {
    return res;
}, async (error: any) => {
    if (error.config && error.response && error.response.status === 401) {
        error.config._retry = true;
        const refreshToken = localStorage.getItem('authToken');
        error.config.headers.refreshToken = refreshToken;

        return axios.create({
            baseURL: BASE_URL,
            headers: {
                'Authorization': 'Bearer ' + refreshToken,
                'Content-Type': 'application/json',
                withCredentials: true
            }
        })
        // .then((res) => {
        //     if (res.status === 200 && res.data.refreshToken) {
        //         localStorage.setItem('authToken', res.data.accessToken)
        //         const accessToken = localStorage.getItem('authToken')
        //         error.config.headers["Authorization"] = accessToken
        //         return axiosInstance(error.config)
        //     }
        // })
    }

    return Promise.reject(error)
})

const baseAxios = (url: string) => {
    return new DefaultApi({
        apiKey: localStorage.getItem('authToken'),
    } as Configuration, url, axiosInstance)
}

const BaseInstance = baseAxios(BASE_URL)
export default BaseInstance;
