import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

function TimeComponent() {
    const [formattedDate, setFormattedDate] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const today = new Date();
            const year = today.getFullYear();
            const month = today.getMonth() + 1;
            const date = today.getDate();
            const hours = today.getHours();
            const minutes = today.getMinutes();
            const ampm = hours >= 12 ? '오후' : '오전';
            const hours12 = hours % 12 || 12;
            const newFormattedDate = `${year}-${month < 10 ? '0' + month : month}-${date < 10 ? '0' + date : date} \n${ampm} ${hours12}시${minutes < 10 ? '0' + minutes : minutes}분`;
            setFormattedDate(newFormattedDate);
        }, 1000);
    
        return () => {
            clearInterval(interval);
        };
    }, []);

    return <TimeText>{formattedDate}</TimeText>;
}

export default TimeComponent;

const TimeText = styled.p`
    color : white;
    font-size : 22px;
    font-family: MBK CorpoS;
    white-space: pre-wrap;
`;