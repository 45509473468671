import styled from "@emotion/styled";

interface TableTrProps {
    children: React.ReactNode;
}

export const TableTr = ({ children } : TableTrProps) => {
    return <Tr>{children}</Tr>
}

const Tr = styled.tr`
    width : 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding : 8px;
`;