import styled from "@emotion/styled";

interface HeaderLogoutBoxProps {
    children: React.ReactNode;
    isLogin: boolean;
}

export const HeaderLogoutBox = ({ children, isLogin } : HeaderLogoutBoxProps) => {
    return <Wrapper isLogin={isLogin}>{children}</Wrapper>
}

const Wrapper = styled.div<{ isLogin : boolean }>`
    display: ${({ isLogin }) => (isLogin ? "block" : "none")};
`;