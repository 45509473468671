import styled from "@emotion/styled";

interface HeaderTitleProps {
    children: React.ReactNode;
}

export const HeaderTitle = ({ children } : HeaderTitleProps) => {
    return <Title>{children}</Title>
}

const Title = styled.p`
    color : white;
    font-size : 38px;
    font-family: MBK CorpoS;
    padding : 8px 16px;
`;