import styled from "@emotion/styled";

interface TableContainerProps {
    children: React.ReactNode;
}

export const TableContainer = ({ children } : TableContainerProps) => {
    return <Table>{children}</Table>
}

const Table = styled.table`
    width : 100%;
    height : 100%;
    padding : 8px 22px;
`