import styled from "@emotion/styled";

interface TableTheadProps {
    children : React.ReactNode;
}

export const TableThead = ({ children } : TableTheadProps) => {
    return <Thead>{children}</Thead>
}

const Thead = styled.thead`
    width : 100%;
`