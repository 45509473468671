import styled from "@emotion/styled";

interface TableTbodyTrProps {
    children: React.ReactNode;
}

export const TableTbodyTr = ({ children } : TableTbodyTrProps) => {
    return <Tr>{children}</Tr>
}

const Tr = styled.tr`
    width : 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom : 1px solid white;
    padding : 16px;

    &:hover {
        background-color: gray;
        cursor : pointer;
    }
`