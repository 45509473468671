import styled from "@emotion/styled";

interface TableTbodyProps {
    children : React.ReactNode;
}

export const TableTbody = ({ children } : TableTbodyProps) => {
    return <Tbody>{children}</Tbody>
};

const Tbody = styled.tbody`
    width : 100%;
    height: 100%;
`;