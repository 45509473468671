import styled from "@emotion/styled";

interface SliderWrapperProps {
    children: React.ReactNode;
}

export const SliderContent= ({ children } : SliderWrapperProps) => {
    return <Content>{children}</Content>
}

const Content = styled.div`
    display: flex;
    width: 100%;
    height : 100%;
`;