import { AxiosResponse } from "axios";
import BaseInstance from "instance/axios";
import { useQuery } from "react-query";

const TICKECT_GET_QUERY_KEY = "/tickets";

const fetchTicketGet = (params : {page : number}) => BaseInstance.ticketsGet(
    {},
    {
        params : {
            page : params.page,
            pageSize : 10,
            populate : ["createUser", "stagesInfo"],
            
        }
    }
);

export const useTicketGetQuery = (params:{page : number}) => {
    return useQuery<AxiosResponse>({
        queryKey: [TICKECT_GET_QUERY_KEY, params.page.toString()],
        queryFn: () => fetchTicketGet(params),
    });
}