import styled from "@emotion/styled";

interface TableThProps {
    children: React.ReactNode;
}

export const TableTh = ({ children } : TableThProps) => {
    return <Th>{children}</Th>
}

const Th = styled.th`
    flex : 1;
    font-size : 24px;
    font-weight: 700;
    font-family: MBK CorpoS;
    color : white;
`;