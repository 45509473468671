

import { ReactComponent as Benz_Icon } from "../images/svg/benz_icon.svg";
import { ReactComponent as BenzPhrase_Icon } from "../images/svg/benzPhrase_icon.svg";


export const icons = {
  Benz_Icon,
  BenzPhrase_Icon,
};
