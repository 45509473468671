import styled from "@emotion/styled";

interface HeaderSubBoxProps {
    children: React.ReactNode;
}

export const HeaderSubBox = ({ children } : HeaderSubBoxProps) => {
    return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.div`
    display : flex;
    gap : 12px;
`