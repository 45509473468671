import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useCheckAuth } from "utils/checkAuth";
import { useTicketGetQuery } from "./api/useTicketGetQuery";
import TimeComponent from "./components/Time";
import { useLogout } from "./hook/useLogout";

import { HeaderBox } from "components/\bheaders/HeaderBox";
import { HeaderButton } from "components/\bheaders/HeaderButton";
import { HeaderLogoutBox } from "components/\bheaders/HeaderLogoutBox";
import { HeaderMain } from "components/\bheaders/HeaderMain";
import { HeaderSubBox } from "components/\bheaders/HeaderSubBox";
import { HeaderTitle } from "components/\bheaders/HeaderTitle";

import { SliderContainer } from "components/slider/SliderContainer";
import { SliderContent } from "components/slider/SliderContent";
import { SliderMain } from "components/slider/SliderMain";

import { TableComponents } from "components/Table";

const Header = Object.assign(HeaderMain, {
    Title: HeaderTitle,
    Box: HeaderBox,
    SubBox: HeaderSubBox,
    LogoutBox: HeaderLogoutBox,
    Button: HeaderButton,
});

const Slider = Object.assign(SliderMain, {
    Container: SliderContainer,
    Content: SliderContent,
})

export const Dashboard = () => {

    const [currentPage, setCurrentPage] = useState(1); // server에 보내는 page 값
    const [ page, setPage ] = useState<number>(0); // 현재 페이지
    const [ totalPage, setTotalPage ] = useState<number>(0); // 총 페이지
    const [tickets, setTickets] = useState<any>();
    const [evenTickets, setEvenTickets] = useState<any>();
    const isLogin = useCheckAuth();
    const logout = useLogout();

    const { data: firstData } = useTicketGetQuery({ page: currentPage });
    const { data: secondData } = useTicketGetQuery({ page: currentPage + 1});
    
    useEffect(() => {
        if (firstData) {
            setTickets(firstData?.data);
            setPage(currentPage)
            setTotalPage(firstData?.data?.totalPages)
        }
        if (secondData) {
            setEvenTickets(secondData?.data);
        }
    }, [firstData, secondData])

    return (
        <>
            <Header.Box>
                <Header.SubBox>
                    <Header.Title>차량번호</Header.Title>
                    <Header.Title>{Math.ceil(page / 2)} / {Math.ceil(totalPage / 2)}</Header.Title>
                </Header.SubBox>
                <Header.SubBox>
                    <TimeComponent />
                    <Header.LogoutBox isLogin={isLogin}>
                        <Header.Button onClick={logout}>로그아웃</Header.Button>
                    </Header.LogoutBox>
                </Header.SubBox>
            </Header.Box>
            <GradientBar />
            <Slider.Container setCurrentPage={setCurrentPage} firstData={firstData} >
                <Slider.Content>
                    <TableComponents data={tickets} />
                </Slider.Content>
                <Slider.Content>
                    <TableComponents data={evenTickets} />
                </Slider.Content>
            </Slider.Container>
        </>
    )
}

const GradientBar = styled.div`
  width: 100%;
  height: 0.8rem;
  margin-top: 1.1rem;
  border-bottom: 0.5rem solid transparent;
  background: linear-gradient(to right, #000000, #565656, #ffffff, #565656, #000000); /* 그라데이션 배경 */
  background-clip: content-box;
`;