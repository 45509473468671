import styled from "@emotion/styled";

interface HeaderButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
}

export const HeaderButton = ({ children, onClick } : HeaderButtonProps) => {
    return <Button onClick={onClick}>{children}</Button>
}

const Button = styled.button`
    color: white;
    border-radius: 4px;
    padding: 0.7rem;
    color: white;
    font-size: 3.2rem;
    font-family: MBK CorpoS;
    font-weight: 400;
    letter-spacing: 0.032rem;
    word-wrap: break-word;

    &:hover {
        background-color: gray;
        cursor: pointer;
    }
`