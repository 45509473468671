import styled from "@emotion/styled";

export const GradientBar = () => {
    return (
        <Bar />
    )
};

const Bar = styled.div`
    width: 100%;
    height: 0.8rem;
    margin-top: 1.1rem;
    border-bottom: 0.5rem solid transparent;
    background: linear-gradient(to right, #000000, #565656, #ffffff, #565656, #000000); /* 그라데이션 배경 */
    background-clip: content-box;
`