import styled from "@emotion/styled";
import axios from "axios";
import { BASE_URL } from "instance/axios";
import { icons } from "modules/icons";
import { useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { footerInfoAtom } from "recoil/layoutAtom";

type USER_ROLES = "ADMIN" | "CENTER_ADMIN" | "TECHNICIAN" | "ADVISER";

export const ROLE_MAP: {
    [key in USER_ROLES]: string;
} = {
    "ADMIN": "개발자",
    "CENTER_ADMIN": "관리자",
    "TECHNICIAN": "테크니션",
    "ADVISER": "어드바이저",
}

function Footer() {
    const setFooterInfo = useSetRecoilState(footerInfoAtom);

    const loadUserData = useCallback(async () => {
        const authToken = localStorage.getItem("authToken");

        if (authToken) {
            const result = await axios.get(`${BASE_URL}/whoami`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
            })

            setFooterInfo({
                centerName: result.data.site?.name,
                managerName: `${ROLE_MAP[result.data.role as USER_ROLES]} ${result.data.name}`,
                workbayName: result.data.workbay?.name,
            });
        }


    }, [setFooterInfo])

    useEffect(() => {
        loadUserData()
    }, [loadUserData])

    const footerInfo = useRecoilValue(footerInfoAtom);

    return (
        <Container>
            <div className="logo">
                <icons.Benz_Icon />
                <icons.BenzPhrase_Icon />
            </div>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default Footer;
