import { TableContainer } from "./TableContainer";
import { TableMain } from "./TableMain";
import { TableTbody } from "./TableTbody";
import { TableTbodyTr } from "./TableTbodyTr";
import { TableTd } from "./TableTd";
import { TableTh } from "./TableTh";
import { TableThead } from "./TableThead";
import { TableTr } from "./TableTr";

import styled from "@emotion/styled";

const Table = Object.assign(TableMain, {
    Container: TableContainer,
    Thead: TableThead,
    Tr: TableTr,
    Th: TableTh,
    Tbody: TableTbody,
    TbodyTr: TableTbodyTr,
    Td: TableTd
})
interface TableComponentsProps {
    data: any;
}
export const TableComponents = ({ data }: TableComponentsProps) => {
    return (
        <>
            <Table.Container>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>차량번호</Table.Th>
                        <Table.Th>모델명</Table.Th>
                        <Table.Th>워크베이</Table.Th>
                        <Table.Th>진행상태</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <GradientBar />
                <Table.Tbody>
                    {data?.rows && data?.rows.map((value: any, index: number) => {
                        const lastLog = value.stagesInfo[0]?.logs[value.stagesInfo[0]?.logs.length - 1]?.stepStatus;
                        const lastAdditional = value.stagesInfo[0]?.logs[value.stagesInfo[0]?.logs.length - 1]?.additional;
                        return (
                            <Table.TbodyTr>
                                <Table.Td>{value.plateCode}</Table.Td>
                                <Table.Td>{value.carModel}</Table.Td>
                                <Table.Td>{value?.createUser?.defaultWorkbayId ? value?.createUser?.defaultWorkbayId + "번" : ''}</Table.Td>
                                <Table.Td>
                                    {lastLog === "IN_PROGRESS" ? '정비 중' : ''}
                                    {lastLog === "PROCESSING" ? `${lastAdditional?.process?.name} 중 ` : ''}
                                    {lastLog === "DONE" ? '정비 완료 ' : ''}
                                    {lastLog === undefined ? '정비 대기': ''}
                                    {lastLog === "PENDING" || lastLog === "PROCESS_PENDING" ? `지연(${lastAdditional?.pendingReason?.name}) ` : ''}
                                </Table.Td>
                            </Table.TbodyTr>
                        )
                    })}
                </Table.Tbody>
            </Table.Container>
        </>
    )
}

const GradientBar = styled.div`
  width: 100%;
  height: 0.8rem;
  margin-top: 1.1rem;
  border-bottom: 0.5rem solid transparent;
  background: linear-gradient(to right, #000000, #565656, #ffffff, #565656, #000000); /* 그라데이션 배경 */
  background-clip: content-box;
`;