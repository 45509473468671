import styled from "@emotion/styled";

interface TableTdProps {
    children : React.ReactNode;
}

export const TableTd = ({ children } : TableTdProps) => {
    return <Td>{children}</Td>
}

const Td = styled.td`
    flex : 1;
    font-size : 24px;
    font-family: MBK CorpoS;
    font-weight: 500;
    color : white;
    text-align: center;
`