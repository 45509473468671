import styled from "@emotion/styled";
import { HeaderBox } from "components/\bheaders/HeaderBox";
import { HeaderButton } from "components/\bheaders/HeaderButton";
import { HeaderLogoutBox } from "components/\bheaders/HeaderLogoutBox";
import { HeaderMain } from "components/\bheaders/HeaderMain";
import { HeaderSubBox } from "components/\bheaders/HeaderSubBox";
import { HeaderTitle } from "components/\bheaders/HeaderTitle";
import { TableContainer } from "components/Table/TableContainer";

import { TableMain } from "components/Table/TableMain";
import { TableTbody } from "components/Table/TableTbody";
import { TableTbodyTr } from "components/Table/TableTbodyTr";
import { TableTd } from "components/Table/TableTd";
import { TableTh } from "components/Table/TableTh";
import { TableThead } from "components/Table/TableThead";
import { TableTr } from "components/Table/TableTr";
import { GradientBar } from "components/bar/GradientBar";
import TimeComponent from "pages/dashboard/components/Time";

const Header = Object.assign(HeaderMain, {
    Title: HeaderTitle,
    Box: HeaderBox,
    SubBox: HeaderSubBox,
    LogoutBox: HeaderLogoutBox,
    Button: HeaderButton,
});

const Table = Object.assign(TableMain, {
    Container: TableContainer,
    Thead: TableThead,
    Tr: TableTr,
    Th: TableTh,
    Tbody: TableTbody,
    TbodyTr: TableTbodyTr,
    Td: TableTd
})

export const Dashboards = () => {
    return (
        <>
            <Header.Box>
                <Header.SubBox>
                    <Header.Title>차량 수리 진행 현황</Header.Title>
                </Header.SubBox>
                <Header.SubBox>
                    <TimeComponent />
                    {/* <Header.LogoutBox isLogin={isLogin}>
                        <Header.Button onClick={logout}>로그아웃</Header.Button>
                    </Header.LogoutBox> */}
                </Header.SubBox>
            </Header.Box>
            <GradientBar />
            <TableContent>
                <Table.Container>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>차량번호</Table.Th>
                            <Table.Th>모델명</Table.Th>
                            <Table.Th>워크베이</Table.Th>
                            <Table.Th>진행상태</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <GradientBar />
                    <Table.Tbody>
                        <Table.TbodyTr>
                            <Table.Td>31가3407</Table.Td>
                            <Table.Td>EQS</Table.Td>
                            <Table.Td>2번</Table.Td>
                            <Table.Td>정비중</Table.Td>
                        </Table.TbodyTr>
                        <Table.TbodyTr>
                            <Table.Td>31가3407</Table.Td>
                            <Table.Td>EQS</Table.Td>
                            <Table.Td>2번</Table.Td>
                            <Table.Td>출고대기</Table.Td>
                        </Table.TbodyTr>
                        <Table.TbodyTr>
                            <Table.Td>31가3407</Table.Td>
                            <Table.Td>A45 AMG</Table.Td>
                            <Table.Td>2번</Table.Td>
                            <Table.Td>정비대기</Table.Td>
                        </Table.TbodyTr>
                        <Table.TbodyTr>
                            <Table.Td>31가3407</Table.Td>
                            <Table.Td>EQS</Table.Td>
                            <Table.Td>2번</Table.Td>
                            <Table.Td>정비중</Table.Td>
                        </Table.TbodyTr>
                        <Table.TbodyTr>
                            <Table.Td>31가3407</Table.Td>
                            <Table.Td>EQS</Table.Td>
                            <Table.Td>2번</Table.Td>
                            <Table.Td>정비중</Table.Td>
                        </Table.TbodyTr>
                        <Table.TbodyTr>
                            <Table.Td>31가3407</Table.Td>
                            <Table.Td>EQS</Table.Td>
                            <Table.Td>2번</Table.Td>
                            <Table.Td>정비중</Table.Td>
                        </Table.TbodyTr>
                    </Table.Tbody>
                </Table.Container>
                <Table.Container>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>차량번호</Table.Th>
                            <Table.Th>모델명</Table.Th>
                            <Table.Th>워크베이</Table.Th>
                            <Table.Th>진행상태</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <GradientBar />
                    <Table.Tbody>
                        <Table.TbodyTr>
                            <Table.Td>31가3407</Table.Td>
                            <Table.Td>EQS</Table.Td>
                            <Table.Td>2번</Table.Td>
                            <Table.Td>정비중</Table.Td>
                        </Table.TbodyTr>
                        <Table.TbodyTr>
                            <Table.Td>31가3407</Table.Td>
                            <Table.Td>EQS</Table.Td>
                            <Table.Td>2번</Table.Td>
                            <Table.Td>출고대기</Table.Td>
                        </Table.TbodyTr>
                        <Table.TbodyTr>
                            <Table.Td>31가3407</Table.Td>
                            <Table.Td>A45 AMG</Table.Td>
                            <Table.Td>2번</Table.Td>
                            <Table.Td>정비대기</Table.Td>
                        </Table.TbodyTr>
                        <Table.TbodyTr>
                            <Table.Td>31가3407</Table.Td>
                            <Table.Td>EQS</Table.Td>
                            <Table.Td>2번</Table.Td>
                            <Table.Td>정비중</Table.Td>
                        </Table.TbodyTr>
                        <Table.TbodyTr>
                            <Table.Td>31가3407</Table.Td>
                            <Table.Td>EQS</Table.Td>
                            <Table.Td>2번</Table.Td>
                            <Table.Td>정비중</Table.Td>
                        </Table.TbodyTr>
                        <Table.TbodyTr>
                            <Table.Td>31가3407</Table.Td>
                            <Table.Td>EQS</Table.Td>
                            <Table.Td>2번</Table.Td>
                            <Table.Td>정비중</Table.Td>
                        </Table.TbodyTr>
                    </Table.Tbody>
                </Table.Container>
            </TableContent>

        </>
    )
};

const TableContent = styled.div`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`