import styled from "@emotion/styled";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

interface SliderContainerProps {
    children: React.ReactNode;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    firstData : any;
}
export const SliderContainer = ({ 
    children,
    setCurrentPage,
    firstData
} : SliderContainerProps) => {

    // react-slick Setting 값
    const Settings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 2,
        slidesToScroll: 2,
        pauseHover : false,
        afterChange: (current: number) => {
            setCurrentPage((prev) => {
                const totalPages = firstData?.data.totalPages;
                if (totalPages) {
                    const nextPage = prev + 2;
                    return nextPage > totalPages ? 1 : nextPage;
                } else {
                    return prev;
                }
            });
        }

    };

    return(
        <Container {...Settings}>
            {children}
        </Container>
    )
}

const Container = styled(Slider)`
    width : 100%;
    height: 100%;
    margin : 0 auto;
    
    .slick-list {
        position : absolute;
        width: 100%;
        height :870px;
        display: flex;
        align-items: center;
        object-fit: cover;
    }

    .slick-slider {
        display: flex;
    }

    .slick-track {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .slick-dots {
        display: none !important;
    }
`